<template>
    <div class="project">
        <div class="material_screen">
            <div class="mian">
                <div class="inner_nav clearfix">
                    <div class="fl inner_nav_l">当前位置：<span @click="backFun">首页</span> > 专题列表</div>
                    <div class="fr inner_nav_r">共 <span>{{spa_total}}</span> 个作品</div>
                </div>
                <router-link class="inner_banner" :to="{name:banner.url,query:{id:banner.links_id!=0?banner.links_id:''} }">
                    <img :src="banner.thumb" alt="" />
                </router-link>
                <div class="material_search">
                    <input type="text" v-model="keywords" placeholder="搜索关键字" @keyup.enter="listFun"/>
                    <div class="material_search_button" @click="listFun">
                        <img src="../assets/images/indexsearch.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="mian">
            <div class="project_box">
                <div class="project_ul clearfix" v-if="list.length>0">
                    <!-- wow animate__animated animate__fadeInUp -->
                    <div class="fl project_li " :style="{animationDelay:0.1*index+'s'}" v-for="(item,index) in list" :key="index" @click="detailFun(item.id)">
                        <div class="project_li_box">
                            <img v-lazy="item.thumb" alt="">
                            <div class="project_li_hot" v-if="item.is_hot == 1">HOT</div>
                            <div class="project_li_buttom">{{item.title}}</div>
                            <div class="project_li_mask"><p>{{item.title}}</p></div>
                            <div class="project_li_collect" v-if="item.is_collect == 0" @click.stop="collectFun(1,item.id,index)"><img src="../assets/images/icon_collection@2x.png" alt=""></div>
                            <div class="project_li_collect" v-else @click.stop="uncollectFun(1,item.id,index)"><img src="../assets/images/icon_collections@2x.png" alt=""></div>
                        </div>
                    </div>
                </div>
                <el-empty v-else description="暂无数据"></el-empty>
                <page v-if="total>12" :page="page" :limit="limit" :total="total"></page>
            </div>
        </div>
        <!-- 侧边导航 -->
        <sidebar ref="downtotal"></sidebar>
        <!-- 收藏 -->
        <div class="mask" v-if="isShow" @click="closeMsg">
            <collection isMask="1" :collectionId="collId" :type="type" @closeFun="closeFun"></collection>
        </div>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'
import sidebar from '../components/sidebar.vue'
import collection from '../components/collection.vue'
import page from '../components/page.vue'
export default {
    name: "project",
    components:{
        sidebar,
        collection,
        page
    },
    data(){
        return{
            spa_total:0,//作品总数
            list:[],

            banner:{},

            isShow:false,//收藏夹显示
            
            keywords:'',//关键词
            // 分页
            total:0,//总数
            page:1,//页数
            limit:12,//每页个数

            // 收藏参数
            type:'',//类型
            collId:'',//收藏id
        }
    },
    mounted(){
        this.$parent.routerIndex = 0;
        this.$parent.showIndex = 1;
        // new WOW().init();
        this.bannerOneban();
        this.listFun();
        this.$refs.downtotal.listFun()
    },
    methods:{
        // 获取内部banner、 
		bannerOneban(){
			var that = this;
            this.$api.POST(this.$face.bannerOneban,{location:12},function(res){
                switch(res.data.links) {
                    case 0:
                        res.data.url = '';
                        break;
                    case 1:
                        res.data.url = 'material';
                        break;
                    case 2:
                        res.data.url = 'video';
                        break;
                    case 3:
                        res.data.url = 'deformation';
                        break;
                    case 4:
                        res.data.url = 'ranking';
                        break;
                    case 5:
                        res.data.url = 'dailyWord';
                        break;
                    case 6:
                        res.data.url = 'fontSearch';
                        break;
                    case 7:
                        res.data.url = 'dailyWordUpload';
                        break;
                    case 8:
                        res.data.url = 'vip';
                        break;
                    case 9:
                        res.data.url = 'project';
                        break;
                    case 10:
                        res.data.url = 'rankingDetail';
                        break;
                    case 11:
                        res.data.url = 'projectDetail';
                        break;
                }
                that.banner = res.data;
            })
		},
        listFun(){
            var that = this;
            var params ={
                page: this.page,
                limit: this.limit,
                _keywords: this.keywords
            }
            this.$api.POST(this.$face.fontSpecial,params,function(res){
                that.list = res.data.list;
                that.total = res.data.total;
                that.spa_total = res.data.total_spa;
            })
        },

        detailFun(id){
            this.$router.push({name:'projectDetail',query:{id:id}})
        },

        //收藏列表
        collectFun(type,id,index){
            if(localStorage.getItem('access_token')){
                this.type = type;
                this.collId = id;
                this.isShow = true;
            }else{
                this.$parent.loginShow()
            }
        },
        //取消收藏
        uncollectFun(type,id,index){
            var that = this;
            var params ={
                coll_id: id,
                type: type,
            }
            this.$api.POST(this.$face.collectionColl,params,function(res){
                that.list[index].is_collect = 0;
                that.$utile.prompt('success','取消收藏');
            })
        },

        //关闭收藏
        closeFun(){
            this.isShow = false
        },
        // 点击遮罩层关闭
        closeMsg(ev){
            let dom = document.getElementById("child");
            if(dom){
                if(!dom.contains(ev.target)){
                    this.isShow = false
                }
            } 
        },
        //面包屑
        backFun(){
            localStorage.setItem('clearAll',1)
            this.$router.push({name:'refresh',query:{usernext:'/'}})
        },
    },
    watch:{
        
    }
};
</script>

<style scoped>
.project {
    background-color: #f6f6f6;
}

.material_screen {
    width: 100%;
    background: #ffffff;
    padding-bottom: 26px;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r span {
    color: #5957FF;
}

.inner_banner {
    display: block;
    margin-bottom: 24px;
}
.inner_banner img {
    width: 100%;
}

.material_search {
    width: 100%;
    height: 70px;
    background-color: #f6f7f9;
    border-radius: 10px;
    border: solid 1px #eeeeee;
    box-sizing: border-box;
    padding-right: 160px;
    position: relative;
}
.material_search input {
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    font-size: 16px;
}
.material_search input::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #999999;
}

.material_search_button{
    position: absolute;
    top: 2px;
    right: 2px;
    width: 150px;
    height: 64px;
    background-image: linear-gradient(0deg, 
    #ffc346 0%, 
    #ffdc5b 100%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.project_box{
    padding-bottom: 54px;
}
/* 专题 */
.project_ul{
    margin: 28px -13.33px 0;
    padding-bottom: 25px;
}
.project_li{
    width: 330px;
    height: 314px;
    margin: 0 13.33px 30px;
    padding-top: 15px;
    background: url(../assets/images/bg_tjzt2x.png) no-repeat;
    background-size: 311px auto;
    background-position: center top;
}
.project_li_box{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    width: 330px;
    height: auto;
}
/* .project_li_box>img,.video_li_box>img,.daily_li_box>img{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
} */
/* 2022年10.8号修改 */
.project_li_box>img{
   /* position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); */
    width: 100%;
    height: auto;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.project_li_hot{
    position: absolute;
    top: 14px;
    left: -10px;
    width: 62px;
    height: 40px;
    background-color: #df0003;
    border-radius:10px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0px;
    color: #ffffff;
    z-index: 101;
    transition: 0.5s;
    padding-left: 10px;
    box-sizing: border-box;
}
.project_li:hover .project_li_hot{
    left: 15px;
    padding-left: 0px;
}
.project_li_buttom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    text-align: left;
    padding: 0 16px;
    box-sizing: border-box;
    line-height: 45px;
    color: #FFF;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: rgba(0,0,0,.4);
    opacity: 1;
    z-index: 33;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.project_li_mask{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0;
    z-index: 34;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: default;
}
.project_li_collect{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 14px;
    right: 15px;
    z-index: 100;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    opacity: 0;
    cursor: pointer;
}
.project_li_collect img{
    width: 100%;
    height: 100%;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.project_li_collect:hover img{
    
    opacity: 0.8;
}
/* .project_li:hover .project_li_box > img,.video_li:hover .video_li_box>img,.daily_li:hover .daily_li_box>img{
	transform: scale(1.08) translateX(-46%);
} */
/* 2022年10.8号修改 */
.project_li:hover .project_li_box > img,.video_li:hover .video_li_box>img,.daily_li:hover .daily_li_box>img{
	transform: scale(1.08);
}
.project_li:hover .project_li_buttom{
    opacity: 0;
}
.project_li:hover .project_li_mask, .project_li:hover .project_li_collect{
    opacity: 1;
}


/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.ranking_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.ranking_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */

.sidebar_r{
    right: 2%;
    left: auto;
}
</style>